.about {
    grid-column: 3/13;
    padding: 10rem 3rem;
    background-color: #fff;
    color: $gray;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    grid-gap: 4rem;
    align-items: center;

    &__img {
        width: 80%;
        justify-self: left;
    }

    &__content {
        justify-self: left;
    }
}